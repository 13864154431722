import React, { useEffect } from "react";

// Chakra imports
import { Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// Icons
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import SkeletonTable from "components/Tables/SkeletonTable";
import { decodeMessageData } from "utils/constants";
import { FETCH_LAST_FINALIZED_BLOCK } from "utils/queries";
import BlockRow from "views/BlockDetails/BlockRow";

const BlockDetails = () => {
  const [blockDetails, setBlockDetails] = useState([]);
  const [decodedBlockDetails, setDecodedBlockDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { blockNumber } = useParams();
  const [getBlockDetails, { data, loading }] = useLazyQuery(
    FETCH_LAST_FINALIZED_BLOCK,
    {
      variables: {
        orderBy: `timestamp`,
        orderDirection: `desc`,
      },
    }
  );
  useEffect(() => {
    fetchBlockDetails();
  }, [blockNumber]);

  useEffect(() => {
    console.log(data);
    data?.finalizeBlockEvents && setBlockDetails(data?.finalizeBlockEvents);
  }, [data]);

  useEffect(() => {
    if (blockDetails.length > 0) {
      const decoded = decodeMessageData(blockDetails[0].messageData);
      setDecodedBlockDetails(decoded);
    }
  }, [blockDetails]);

  useEffect(() => {
    console.log(decodedBlockDetails);
  }, [decodedBlockDetails]);

  const fetchBlockDetails = async () => {
    setIsLoading(true);
    await getBlockDetails();
    setIsLoading(false);
  };

  return (
    <Card
      overflowX={{ sm: "scroll", xl: "hidden" }}
      pb="0px"
      className="scroll-content"
      mb="4rem"
    >
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="lg" color="#fff" fontWeight="bold">
          Last Blocks Data
        </Text>
      </CardHeader>
      <CardBody>
        <Table variant="simple" color="#fff">
          <Thead>
            <Tr my=".8rem" ps="0px" color="gray.400">
              <Th
                ps="0px"
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
              >
                Request ID
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
                textTransform="uppercase"
              >
                Requesting Chain Domain
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
                textTransform="uppercase"
              >
                Destination Chain Domain
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
                textTransform="uppercase"
              >
                Request Type
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
                textTransform="uppercase"
              >
                Message
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
                textTransform="uppercase"
              >
                Collection ID
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
                textTransform="uppercase"
              >
                Result
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
                textTransform="uppercase"
              >
                Power
              </Th>
              <Th
                color="gray.400"
                fontFamily="Plus Jakarta Display"
                borderBottomColor="#56577A"
                textTransform="uppercase"
              >
                Collection Name Hash
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <SkeletonTable rowCount={1} />
            ) : decodedBlockDetails.values.length == 0 ? (
              <BlockRow
                key={0}
                decodedBlockDetails={decodedBlockDetails}
                lastItem={true}
              />
            ) : (
              decodedBlockDetails.values.length > 0 &&
              decodedBlockDetails.values.map((row, index, arr) => {
                return (
                  <BlockRow
                    key={index}
                    decodedBlockDetails={row}
                    lastItem={index === arr.length - 1}
                  />
                );
              })
            )}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default BlockDetails;
