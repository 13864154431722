/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// Table Components
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";

// Data
import { tablesProjectData, tablesTableData } from "variables/general";

// Icons
import { AiFillCheckCircle } from "react-icons/ai";
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { FETCH_VALIDATORS } from "utils/queries";
import TablesValidatorsRow from "components/Tables/TablesValidatorsRow";
import { useParams } from "react-router-dom";
import SkeletonTable from "components/Tables/SkeletonTable";
import { FETCH_VALIDATOR_BY_ADDRESS } from "utils/queries";
import Header from "./Header";
import Attestations from "views/Attestations/Attestations";

const ValidatorDetails = () => {
  const [validatorsData, setValidatorsData] = useState([]);
  const { validatorAddress } = useParams();
  const [getValidatorData, { data: signerAddressData }] = useLazyQuery(
    FETCH_VALIDATOR_BY_ADDRESS,
    {
      variables: {
        address: validatorAddress,
      },
    }
  );
  useEffect(() => {
    fetchValidatorInfo();
  }, [validatorAddress]);

  useEffect(() => {
    signerAddressData?.validators &&
      setValidatorsData(signerAddressData?.validators);
  }, [signerAddressData]);

  const fetchValidatorInfo = async () => {
    await getValidatorData();
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Header />
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
        className="scroll-content"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Validator Details
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff">
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  ID
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Validator
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Status
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Stake
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Last Finalized Block
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Last Attested Dynasty
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Last Attested Epoch
                </Th>

                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Last Epoch Churned Out
                </Th>
                <Th borderBottomColor="#56577A"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {validatorsData.length > 0 ? (
                validatorsData.map((row, index, arr) => {
                  return (
                    <TablesValidatorsRow
                      key={row.address}
                      address={row.address}
                      validatorId={row.validatorId}
                      stake={row.stake / 1e18}
                      lastAttestedDynasty={row.lastAttestedDynasty}
                      lastAttestedEpoch={row.lastAttestedEpoch}
                      lastFinalizedBlock={row.lastFinalizedBlock}
                      status={row.isChurnedOut}
                      lastEpochChurnedOut={row.lastEpochChurnedOut}
                      lastItem={index === arr.length - 1 ? true : false}
                    />
                  );
                })
              ) : (
                <SkeletonTable rowCount={10} />
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <Attestations />
    </Flex>
  );
};

export default ValidatorDetails;
