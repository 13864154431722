import { Badge, Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import Address from "components/Address/Address";
import NumberWithTooltip from "components/Text/NumberWithTooltip";
import React from "react";

const ActiveSetRow = (props) => {
  const { address, stake, validatorId, status, lastItem } = props;
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps="0px"
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Address address={address} />
            <Text
              fontSize="sm"
              color="gray.400"
              fontWeight="bold"
              marginTop={2}
            >
              Validator ID: {validatorId}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          <NumberWithTooltip value={stake} />
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Badge
          bg={status === true ? "transparent" : "green.400"}
          color={status === true ? colorStatus : "white"}
          fontSize="sm"
          p="3px 10px"
          borderRadius="8px"
          border={status == true ? "1px solid #fff" : "none"}
          fontWeight="bold"
        >
          {status == true ? "Inactive" : "Active"}
        </Badge>
      </Td>
    </Tr>
  );
};

export default ActiveSetRow;
