/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";

import { HomeIcon } from "components/Icons/Icons";
import { BiSolidBook } from "react-icons/bi";
import { FaUserFriends } from "react-icons/fa";
import { FaCubes } from "react-icons/fa6";
import { VscChecklist } from "react-icons/vsc";
import Validators from "views/Validators/Validators";
import Transactions from "views/Transactions/Transactions";
import Blocks from "views/Blocks/Blocks";
import Attestations from "views/Attestations/Attestations";
import ValidatorDetails from "views/ValidatorDetails/ValidatorDetails";
import TransactionDetails from "views/TransactionDetails/TransactionDetails";
import BlockDetails from "views/BlockDetails/BlockDetails";

var dashRoutes = [
  {
    path: "/",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    element: <Dashboard />,
    layout: "/",
    hasQueryParam: false,
  },
  {
    path: "validators",
    name: "Validators",
    rtlName: "لوحة القيادة",
    icon: <FaUserFriends color="inherit" />,
    element: <Validators />,
    layout: "/",
    hasQueryParam: false,
  },
  {
    path: "validators/:validatorAddress",
    name: "Validator Details",
    rtlName: "لوحة القيادة",
    icon: <FaUserFriends color="inherit" />,
    element: <ValidatorDetails />,
    layout: "/",
    hasQueryParam: true,
  },
  {
    path: "blocks/:blockNumber",
    name: "Block Details",
    rtlName: "لوحة القيادة",
    icon: <FaUserFriends color="inherit" />,
    element: <BlockDetails />,
    layout: "/",
    hasQueryParam: true,
  },
  {
    path: "transactions",
    name: "Transactions",
    rtlName: "لوحة القيادة",
    icon: <BiSolidBook color="inherit" />,
    element: <Transactions />,
    layout: "/",
    hasQueryParam: false,
  },
  {
    path: "transactions/:transactionHash",
    name: "Transaction Details",
    rtlName: "لوحة القيادة",
    icon: <FaUserFriends color="inherit" />,
    element: <TransactionDetails />,
    layout: "/",
    hasQueryParam: true,
  },
  {
    path: "blocks",
    name: "Blocks",
    rtlName: "لوحة القيادة",
    icon: <FaCubes color="inherit" />,
    element: <Blocks />,
    layout: "/",
    hasQueryParam: false,
  },
  {
    path: "attestations",
    name: "Attestations",
    rtlName: "لوحة القيادة",
    icon: <VscChecklist color="inherit" />,
    element: <Attestations />,
    layout: "/",
    hasQueryParam: false,
  },

  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   rtlName: "صفحات",
  //   state: "pageCollapse",
  //   views: [
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       rtlName: "لوحة القيادة",
  //       icon: <PersonIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       component: Profile,
  //       layout: "/",
  //     },
  //   ],
  // },
];
export default dashRoutes;
