import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Address from "components/Address/Address";
import React from "react";
import { getTimeFromNow } from "utils";

const AttestationsRow = ({ attestation }) => {
  const {
    epoch,
    dynasty,
    action,
    sender,
    signerAddress,
    transactionHash,
    timestamp,
  } = attestation;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {/* {moment.unix(timestamp).fromNow()} */}
          {getTimeFromNow(timestamp)}
        </Text>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Flex direction="column">
          <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
            {epoch}
          </Text>
        </Flex>
      </Td>
      <Td borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".2rem">
          {dynasty}
        </Text>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          <Link to={`/transactions/${transactionHash}`}>{transactionHash}</Link>
        </Text>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          <Address address={sender} />
          {/* {address} */}
        </Text>
      </Td>
      <Td
        borderBottomColor="#56577A"
        // border={lastItem ? "none" : null}
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
          <Address address={signerAddress} allowRedirection={false} />
          {/* {address} */}
        </Text>
      </Td>
    </Tr>
  );
};

export default AttestationsRow;
