import {
    Badge,
    Box,
    Button,
    Code,
    Flex,
    Td,
    Text,
    Tr,
    useClipboard,
    useColorModeValue,
  } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Address from "components/Address/Address";
  import React, { useState } from "react";
import { BiSolidUpArrowSquare } from "react-icons/bi";
import { getTimeFromNow } from "utils";
import { IoCheckmarkDoneSharp, IoCopyOutline } from "react-icons/io5";
  
  const BlocksRow = ({row, lastItem}) => {
    const {
      blockWinner,
      epoch,
      dynasty,
      messageData,
      sender,
      transactionHash,
      timestamp,
      signerAddress
    } = row;
    const [isCopied, setIsCopied] = useState(false);
    const { onCopy } = useClipboard(messageData);

    const handleCopy = () => {
        onCopy();
        setIsCopied(true);
        // Reset the copied state after a delay
        setTimeout(() => setIsCopied(false), 2000);
      };
  
    return (
      <Tr>
        <Td
          minWidth={{ sm: "250px" }}
          ps='0px'
          border={lastItem ? "none" : null}
          borderBottomColor='#56577A'>
          <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
            <Flex direction='column'>
            <Address address={sender} />
              {/* <Text fontSize='sm' color='gray.400' fontWeight='bold' marginTop={2}>
              Validator ID: {validatorId}
            </Text> */}
            </Flex>
          </Flex>
        </Td>

        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
          <Text fontSize='sm' color='#fff' fontWeight='bold'>
            {getTimeFromNow(timestamp)}
          </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
            <Flex alignItems="center">
                <Box className="cursor-pointer" mr="2">
                <BiSolidUpArrowSquare
                    onClick={() => {}}
                />
                </Box>
                <Code className="code-ellipsis ml-2">
                {messageData}
                </Code>
                <Button onClick={handleCopy} padding={0} colorScheme="gray" ml="4px">
                    {isCopied ? <IoCheckmarkDoneSharp /> : <IoCopyOutline />}
                </Button>
            </Flex>
        </Td>

        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
          <Text fontSize='sm' color='#fff' fontWeight='bold'>
            {blockWinner}
          </Text>
        </Td>

        <Td
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
        >
            <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem" className="custom-ellipsis">
                <Link to={`/transactions/${transactionHash}`}>{transactionHash}</Link>
            </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
            <Text fontSize='sm' color='#fff' fontWeight='bold'>
                <Link to={`/blocks/${epoch}`}>{epoch}</Link>
            </Text>
        </Td>
        <Td border={lastItem ? "none" : null} borderBottomColor='#56577A'>
            <Text fontSize='sm' color='#fff' fontWeight='bold'>
            {dynasty}
            </Text>
        </Td>
        <Td
            border={lastItem ? "none" : null}
            borderBottomColor='#56577A'>
            <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
            <Flex direction='column'>
            <Address address={signerAddress} allowRedirection={false}/>
            </Flex>
            </Flex>
        </Td>
      </Tr>
    );
  }
  
  export default BlocksRow;
  