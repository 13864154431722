import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  useToast,
  Code,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
//   import ActionDrawer from "./ActionDrawer";
import { ethers } from "ethers";
import { zeroBigNumber } from "utils/constants";
import { getWithdrawInitiationPeriod } from "utils/contracts";
import { getdTokenBalance } from "utils/contracts";
import { getDynasty } from "utils/contracts";
import { ChevronDownIcon, InfoIcon } from "@chakra-ui/icons";
import ActionDrawer from "./Drawer/ActionDrawer";
import {
  getUnstakeLock,
  quitNetwork as quitNetworkTx,
  getWithdrawLock,
  getLatestBlockTimestamp,
  getTimeFrom,
  approveDToken,
} from "utils/contracts";

const initLockState = {
  amount: null,
  unlockAfter: zeroBigNumber,
  unlockBefore: zeroBigNumber,
};

const ContractActions = ({
  signerAddressData,
  validatorId,
  delegationAccepted,
}) => {
  const { address, status } = useAccount();
  const { validatorAddress } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [actionDrawer, setActionDrawer] = useState(false);
  const [actionType, setActionType] = useState("");
  const [unstakeLock, setUnstakeLock] = useState(initLockState);
  const [withdrawLock, setIsWithdrawLock] = useState(initLockState);
  const [dTokenAddress, setDTokenAddress] = useState(null);
  const [dTokenAmount, setDTokenAmount] = useState(null);
  const [isQuitNetworkAllowed, setIsQuitNetworkAllowed] = useState(false);
  const [isSignalWithdrawAllowed, setIsSignalWithdrawAllowed] = useState(false);
  const [isInitiateWithdrawAllowed, setIsInitiateWithdrawAllowed] = useState(
    false
  );
  const [isWithdrawAllowed, setIsWithdrawAllowed] = useState(false);
  const [isResetLockAllowed, setIsResetLockAllowed] = useState(false);
  const [dynasty, setDynasty] = useState(0);
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    if (signerAddressData && address) {
      // TODO: check case here
      if (signerAddressData?.address?.toLowerCase() === address.toLowerCase()) {
        setIsQuitNetworkAllowed(true);
      }
    } else {
      setIsQuitNetworkAllowed(false);
    }
  }, [signerAddressData, address]);

  useEffect(() => {
    popoverOpen && fetchDynasty();
  }, [popoverOpen]);

  useEffect(() => {
    fetchTimestamp();
  }, []);

  useEffect(() => {
    if (signerAddressData?.dToken) {
      setDTokenAddress(signerAddressData.dToken);
      fetchDelegatedAmount();
    }
  }, [signerAddressData]);

  useEffect(() => {
    console.log(unstakeLock);
  }, [unstakeLock]);

  useEffect(() => {
    if (dTokenAddress && address) {
      fetchDelegatorLockInfo();
    }
  }, [address, dTokenAddress]);

  const handleRPCError = (error) => {
    return <Code colorScheme="black">{error.message}</Code>;
  };

  useEffect(() => {
    popoverOpen && checkIfSignalWithdrawAllowed();
    popoverOpen && checkIfInitiateWithdrawAllowed();
    popoverOpen && checkIfWithdrawAllowed();
    popoverOpen && checkIfResetLockAllowed();
  }, [unstakeLock, dTokenAmount, popoverOpen, withdrawLock, address]);

  const fetchDynasty = async () => {
    const dynasty = await getDynasty();
    setDynasty(dynasty);
  };

  const fetchTimestamp = async () => {
    const timestamp = await getLatestBlockTimestamp();
    setTimestamp(timestamp);
  };

  const fetchDelegatedAmount = async () => {
    const tokenBalance = await getdTokenBalance(
      signerAddressData?.dToken,
      address
    );
    setDTokenAmount(ethers.utils.parseEther(tokenBalance));
  };

  const fetchDelegatorLockInfo = async () => {
    const unstakeLock = await getUnstakeLock(address, dTokenAddress);
    const withdrawLock = await getWithdrawLock(address, dTokenAddress);
    const withdrawInitiationPeriod = await getWithdrawInitiationPeriod();

    console.log({
      unstakeLock,
      withdrawLock,
      withdrawInitiationPeriod,
    });

    if (unstakeLock && withdrawInitiationPeriod) {
      const { amount, unlockAfter } = unstakeLock;
      if (!amount.isZero()) {
        const unlockBefore = unlockAfter.add(withdrawInitiationPeriod);
        setUnstakeLock({
          amount,
          unlockAfter,
          unlockBefore,
        });
      }
    }
    if (withdrawLock) {
      const { amount, unlockAfter } = withdrawLock;
      setIsWithdrawLock({
        ...withdrawLock,
        amount,
        unlockAfter,
      });
    }
  };

  // * Checks if signal withdraw should be allowed or not
  // * 1. Check if dToken balance of address is not zero
  // * 2. Check if unstake lock amount is zero
  const checkIfSignalWithdrawAllowed = () => {
    if (!dTokenAmount) {
      setIsSignalWithdrawAllowed(false);
      return;
    }

    const isUnstakeAmountZero = unstakeLock.amount?.isZero();
    const isDtokenBalanceZero = dTokenAmount.isZero();

    console.log({
      function: "checkIfSignalWithdrawAllowed",
      isUnstakeAmountZero,
      isDtokenBalanceZero,
    });

    if (!isDtokenBalanceZero && isUnstakeAmountZero) {
      console.log("Setting signal withdraw allowed as true");
      setIsSignalWithdrawAllowed(true);
    } else {
      console.log("Setting signal withdraw allowed as false");
      setIsSignalWithdrawAllowed(false);
    }
  };

  // * Checks if Initiate Withdraw should be allowed or not
  // * 1. timestamp >= unlockAfter && timestamp < (unlockAfter + withdrawInitiationPeriod)
  const checkIfInitiateWithdrawAllowed = () => {
    const timestampCheck =
      timestamp > unstakeLock.unlockAfter.toNumber() &&
      timestamp < unstakeLock.unlockBefore.toNumber();

    if (timestampCheck && withdrawLock.amount.isZero()) {
      setIsInitiateWithdrawAllowed(true);
    }
  };

  // * Checks if Execute Undelegation should be allowed or not
  // * 1. If withdraw lock unlockAfter != 0
  // * 2. If current timestamp >= withdraw lock unlockAfter
  const checkIfWithdrawAllowed = () => {
    const isWithdrawUnlockAfterZero = withdrawLock.unlockAfter.isZero();
    const timestampCheck = timestamp > withdrawLock.unlockAfter.toNumber();
    if (!isWithdrawUnlockAfterZero && timestampCheck) {
      setIsWithdrawAllowed(true);
    } else {
      setIsWithdrawAllowed(false);
    }
  };

  // * Check if resetLock should be allowed
  // * 1. Check if unstakeLock amount is not zero
  // * 2. check if withdrawLock.unlockAfter is zero
  const checkIfResetLockAllowed = () => {
    if (!unstakeLock.amount) {
      setIsResetLockAllowed(false);
      return;
    }

    const isUnstakeLockAmountZero = unstakeLock.amount?.isZero();
    const isWithdrawUnlockAfterZero = withdrawLock.unlockAfter.isZero();
    if (!isUnstakeLockAmountZero && isWithdrawUnlockAfterZero) {
      setIsResetLockAllowed(true);
    } else {
      setIsResetLockAllowed(false);
    }
  };

  const toggleDrawer = () => {
    setActionDrawer(!actionDrawer);
  };

  const quitNetwork = async () => {
    try {
      if (signerAddressData) {
        const unstakeLock = await getUnstakeLock(
          address,
          signerAddressData.dToken
        );

        if (!unstakeLock.amount?.isZero()) {
          toast({
            status: "error",
            title: `Withdraw signalled already sent for ${unstakeLock.amount?.toNumber()}`,
            isClosable: true,
            duration: 2000,
          });
          console.log(
            "Withdraw already signalled",
            unstakeLock.amount?.toNumber()
          );
          return;
        }

        // * Approve dtokens first
        const dTokenBalance = await getdTokenBalance(
          signerAddressData.dToken,
          address
        );
        const {
          success: approveSuccess,
          txHash: approveTxHash,
          error: approveError,
        } = await approveDToken(dTokenBalance, signerAddressData.dToken);
        console.log({ approveSuccess, approveTxHash, approveError });
        if (approveSuccess) {
          toast({
            status: "success",
            title: "Approve transaction successful",
            description: "Please confirm next transaction to quit network",
            duration: 5000,
          });
        } else {
          toast({
            status: "error",
            title: "Approve failed.",
            description: handleRPCError(approveError),
            duration: 5000,
          });
          return;
        }

        // * Quit network transaction
        const { success, txHash, error } = await quitNetworkTx();
        console.log({ success, txHash, error });
        if (success) {
          toast({
            status: "success",
            title: "Quit Network successful",
            duration: 5000,
          });
        } else {
          toast({
            status: "error",
            title: "Quit Network failed",
            description: handleRPCError(error),
            duration: 5000,
          });
        }
      }
    } catch (error) {
      console.log("error in quitting network", error);
    }
  };

  return (
    <>
      <Menu
        onOpen={() => setPopoverOpen(true)}
        onClose={() => setPopoverOpen(false)}
      >
        <Tooltip
          label={
            !signerAddressData.delegationAccepted
              ? "Delegation not accepted"
              : ""
          }
        >
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            isDisabled={!signerAddressData.delegationAccepted}
          >
            Actions
          </MenuButton>
        </Tooltip>
        <MenuList>
          <MenuItem
            onClick={() => {
              if (delegationAccepted) {
                setActionType("delegate");
                toggleDrawer();
              }
            }}
            className={delegationAccepted ? "" : "disabled-action"}
          >
            Delegate
          </MenuItem>
          <MenuItem
            onClick={quitNetwork}
            className={isQuitNetworkAllowed ? "" : "disabled-action"}
          >
            Quit Network
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (isSignalWithdrawAllowed) {
                setActionType("signalWithdraw");
                toggleDrawer();
              }
            }}
            className={isSignalWithdrawAllowed ? "" : "disabled-action"}
          >
            Signal Withdraw
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (isInitiateWithdrawAllowed) {
                setActionType("initiateWithdraw");
                toggleDrawer();
              }
            }}
            className={isInitiateWithdrawAllowed ? "" : "disabled-action"}
          >
            Initiate Withdraw
            {unstakeLock.unlockAfter.toNumber() > 0 &&
              withdrawLock.unlockAfter.toNumber() === 0 && (
                <Tooltip
                  label={`Initiate between: ${unstakeLock.unlockAfter.toNumber()} (${getTimeFrom(
                    unstakeLock.unlockAfter.toNumber(),
                    timestamp
                  )}) and ${unstakeLock.unlockBefore.toNumber()} (${getTimeFrom(
                    unstakeLock.unlockBefore.toNumber(),
                    timestamp
                  )})`}
                  aria-label="A tooltip"
                >
                  <InfoIcon ml={2} />
                </Tooltip>
              )}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (isWithdrawAllowed) {
                setActionType("withdraw");
                toggleDrawer();
              }
            }}
            className={isWithdrawAllowed ? "" : "disabled-action"}
          >
            Withdraw
            {withdrawLock.unlockAfter.toNumber() > 0 && (
              <Tooltip
                label={`Withdraw after: ${withdrawLock.unlockAfter.toNumber()} (${getTimeFrom(
                  withdrawLock.unlockAfter.toNumber(),
                  timestamp
                )})`}
                aria-label="A tooltip"
              >
                <InfoIcon ml={2} />
              </Tooltip>
            )}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (isResetLockAllowed) {
                setActionType("resetLock");
                toggleDrawer();
              }
            }}
            className={isResetLockAllowed ? "" : "disabled-action"}
          >
            Reset Signal Lock
          </MenuItem>
        </MenuList>
      </Menu>
      <ActionDrawer
        isOpen={actionDrawer}
        onClose={onClose}
        validatorId={validatorId}
        validatorAddress={validatorAddress}
        toggleDrawer={toggleDrawer}
        actionType={actionType}
        signerAddressData={signerAddressData}
        unstakeLock={unstakeLock}
        withdrawLock={withdrawLock}
      />
    </>
  );
};

export default ContractActions;
