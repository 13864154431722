/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";

// Chakra imports
import { Flex, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// Icons
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import SkeletonTable from "components/Tables/SkeletonTable";
import Header from "./Header";
import { FETCH_FINALIZED_BLOCKS_BY_EPOCH } from "utils/queries";
import BlockRow from "./BlockRow";
import { decodeMessageData } from "utils/constants";

const BlockDetails = () => {
  const [blockDetails, setBlockDetails] = useState([]);
  const [decodedBlockDetails, setDecodedBlockDetails] = useState([]);
  const { blockNumber } = useParams();
  const [getBlockDetails, { data, loading }] = useLazyQuery(
    FETCH_FINALIZED_BLOCKS_BY_EPOCH,
    {
      variables: {
        epoch: parseInt(blockNumber),
      },
    }
  );
  useEffect(() => {
    fetchBlockDetails();
  }, [blockNumber]);

  useEffect(() => {
    console.log(data);
    data?.finalizeBlockEvents && setBlockDetails(data?.finalizeBlockEvents);
  }, [data]);

  useEffect(() => {
    if (blockDetails.length > 0) {
      const decoded = decodeMessageData(blockDetails[0].messageData);
      setDecodedBlockDetails(decoded);
    }
  }, [blockDetails]);

  useEffect(() => {
    console.log(decodedBlockDetails);
  }, [decodedBlockDetails]);

  const fetchBlockDetails = async () => {
    await getBlockDetails();
  };

  return (
    <Flex direction="column" pt={{ base: "80px", md: "75px" }}>
      <Header
        blockDetails={blockDetails}
        decodedBlockDetails={decodedBlockDetails}
      />
      <Card
        overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
        mt={20}
        className="scroll-content"
      >
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Block Details
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff">
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Request ID
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                  textTransform="uppercase"
                >
                  Requesting Chain Domain
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                  textTransform="uppercase"
                >
                  Destination Chain Domain
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                  textTransform="uppercase"
                >
                  Collection ID
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                  textTransform="uppercase"
                >
                  Result
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                  textTransform="uppercase"
                >
                  Power
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                  textTransform="uppercase"
                >
                  Collection Name Hash
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {decodedBlockDetails.values.length > 0 ? (
                decodedBlockDetails.values.map((row, index, arr) => {
                  return (
                    <BlockRow
                      key={index}
                      decodedBlockDetails={row}
                      lastItem={index === arr.length - 1}
                    />
                  );
                })
              ) : (
                <SkeletonTable rowCount={10} />
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      {/* <Attestations/> */}
    </Flex>
  );
};

export default BlockDetails;
