/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  TableCaption,
  Box,
} from "@chakra-ui/react";
import { MultiSelect } from "chakra-multiselect";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// Table Components

// Data

// Icons
import { useLazyQuery } from "@apollo/client";
import {
  FETCH_ALL_TRANSACTIONS,
  FETCH_TRANSACTION_BY_ACTION,
  FETCH_TRANSACTION_BY_ADDRESS_ACTION,
  FETCH_TRANSACTION_BY_ADDRESS,
} from "utils/queries";
import TransactionsRow from "components/Tables/TransactionsRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { actionOptions } from "utils/constants";
import SearchBar from "components/SearchBar/SearchBar";

const NUM_TRANSACTIONS = 100;

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [option, setOption] = useState([]);
  const [skip, setSkip] = useState(0);
  const [filterAddress, setFilterAddress] = useState("");
  const [getTransactions, { data, loading }] = useLazyQuery(
    FETCH_ALL_TRANSACTIONS,
    {
      variables: {
        orderBy: `timestamp`,
        orderDirection: `desc`,
        first: NUM_TRANSACTIONS,
        skip: skip,
      },
      pollInterval: 1000 * 100,
    }
  );
  const [getTransactionByAction] = useLazyQuery(FETCH_TRANSACTION_BY_ACTION);
  const [getTransactionByAddressAndFilter] = useLazyQuery(
    FETCH_TRANSACTION_BY_ADDRESS_ACTION
  );
  const [getTransactionByAddress] = useLazyQuery(FETCH_TRANSACTION_BY_ADDRESS);

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    if (data?.transactions) {
      if (skip > 0) {
        setTransactions((transactions) => [
          ...transactions,
          ...data.transactions,
        ]);
      }
    }
  }, [data]);

  useEffect(() => {
    console.log({ transactions });
  }, [transactions]);

  useEffect(() => {
    if (skip > 0) {
      getTransactions({
        variables: {
          orderBy: `timestamp`,
          orderDirection: `desc`,
          first: NUM_TRANSACTIONS,
          skip: skip,
        },
        pollInterval: 1000 * 100,
      });
    }
  }, [skip]);

  const resetData = () => {
    setSkip(0);
    getTransactions({
      variables: {
        orderBy: `timestamp`,
        orderDirection: `desc`,
        first: NUM_TRANSACTIONS,
        skip: 0,
      },
    }).then((response) => {
      const { data } = response;
      setTransactions(data?.transactions);
    });
  };

  useEffect(() => {
    setSkip(0);
    if (option.length > 0 && !filterAddress) {
      getTransactionByAction({
        variables: {
          orderBy: `timestamp`,
          orderDirection: `desc`,
          actions: option,
        },
      }).then((response) => {
        const { data } = response;
        setTransactions(data?.transactions);
      });
    } else if (option.length > 0 && filterAddress) {
      getTransactionByAddressAndFilter({
        variables: {
          address: filterAddress,
          orderBy: "timestamp",
          orderDirection: "desc",
          actions: option,
        },
      }).then((response) => {
        const { data } = response;
        setTransactions(data?.transactions);
      });
    } else if (option.length === 0 && filterAddress) {
      getTransactionByAddress({
        variables: {
          address: filterAddress,
          orderBy: "timestamp",
          orderDirection: "desc",
        },
      }).then((response) => {
        const { data } = response;
        setTransactions(data?.transactions);
      });
    } else {
      resetData();
    }
  }, [option, filterAddress]);

  const setSearchBarData = (data) => {
    setTransactions(data);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {/* Authors Table */}
      <Card pb="0px" className="scroll-content">
        <CardHeader p="6px 0px 22px 0px" w="100%" flexDirection="column">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Transactions Table
          </Text>
          <Flex justifyContent="space-between" w="100%" mt="30px">
            <SearchBar
              setSearchBarData={setSearchBarData}
              resetData={resetData}
              parent="transactions"
              selectedActions={option}
              setFilterAddress={setFilterAddress}
            />

            <Box className="custom-multi-select">
              <MultiSelect
                options={actionOptions}
                value={option}
                onChange={setOption}
                placeholder="Filter by Event"
                size="md"
                multiple
              />
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff" flexDirection="column">
            <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
              <Button
                onClick={() => setSkip(skip + NUM_TRANSACTIONS)}
                isLoading={loading}
              >
                Load More
              </Button>
            </TableCaption>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Event
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Address
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Transaction Hash
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Dynasty
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Timestamp
                </Th>
                {/* <Th borderBottomColor="#56577A"></Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {transactions.length > 0 ? (
                transactions.map((row, index) => {
                  return <TransactionsRow transaction={row} key={index} />;
                })
              ) : (
                <SkeletonTable rowCount={10} />
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Transactions;
